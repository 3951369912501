import { Atom } from ":mods";

export function createLanguageTutorInputs() {
  const SpeakArabic = Atom.Form.createInput("", {
    required: true,
  });
  const SpeakEnglish = Atom.Form.createInput("", {
    required: true,
  });

  const Actions = Atom.Form.createFormActions({
    SpeakArabic,
    SpeakEnglish,
  });
  return {
    Actions,
    SpeakArabic,
    SpeakEnglish,
  };
}
